import { template as template_fb1fb2aefb4a478dbe2d59c0bf4a4818 } from "@ember/template-compiler";
const FKControlMenuContainer = template_fb1fb2aefb4a478dbe2d59c0bf4a4818(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
