import { template as template_591d7dca5b3f448e9a95f4fb3ca42eb1 } from "@ember/template-compiler";
const FKLabel = template_591d7dca5b3f448e9a95f4fb3ca42eb1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
