import { template as template_59e004b12b144cb181fd7a32d0dc2432 } from "@ember/template-compiler";
const WelcomeHeader = template_59e004b12b144cb181fd7a32d0dc2432(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
