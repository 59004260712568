import { template as template_dcce7867d6aa4e27a2a967ca3f3ea681 } from "@ember/template-compiler";
const FKText = template_dcce7867d6aa4e27a2a967ca3f3ea681(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
