import { template as template_919d36492e764cec9f6a26cda6714391 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_919d36492e764cec9f6a26cda6714391(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
