import { template as template_1667b055c8684e0a8e33d69d48de8976 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1667b055c8684e0a8e33d69d48de8976(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
