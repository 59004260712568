import { template as template_78de7756cf1a40d8b364fccdcf38dd35 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_78de7756cf1a40d8b364fccdcf38dd35(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
